// Generated by Framer (4b8bcae)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["OhrbEKF4Z"];

const serializationHash = "framer-VIL9n"

const variantClassNames = {OhrbEKF4Z: "framer-v-16jn46g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, newTab, text, width, ...props}) => { return {...props, JuJf4Hk1b: text ?? props.JuJf4Hk1b ?? "Text", LIdfem8Cn: link ?? props.LIdfem8Cn, NAcu3GUJr: newTab ?? props.NAcu3GUJr} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, JuJf4Hk1b, LIdfem8Cn, NAcu3GUJr, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OhrbEKF4Z", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LIdfem8Cn} openInNewTab={NAcu3GUJr}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-16jn46g", className, classNames)} framer-1p7tjdt`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"OhrbEKF4Z"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1jq2s2y"} data-framer-name={"Border"} layoutDependency={layoutDependency} layoutId={"q2W1cqEoK"} style={{backgroundColor: "rgb(0, 0, 0)"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UmVkIEhhdCBUZXh0LTMwMA==", "--framer-font-family": "\"Red Hat Text\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "300", "--framer-letter-spacing": "0px", "--framer-line-height": "120%", "--framer-text-alignment": "left"}}>Text</motion.p></React.Fragment>} className={"framer-1vfnxwa"} data-framer-name={"My Clients"} fonts={["GF;Red Hat Text-300"]} layoutDependency={layoutDependency} layoutId={"hJ53WZg0N"} text={JuJf4Hk1b} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VIL9n.framer-1p7tjdt, .framer-VIL9n .framer-1p7tjdt { display: block; }", ".framer-VIL9n.framer-16jn46g { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-VIL9n .framer-1jq2s2y { flex: none; height: 2px; overflow: hidden; position: relative; width: 24px; }", ".framer-VIL9n .framer-1vfnxwa { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-VIL9n.framer-16jn46g { gap: 0px; } .framer-VIL9n.framer-16jn46g > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-VIL9n.framer-16jn46g > :first-child { margin-left: 0px; } .framer-VIL9n.framer-16jn46g > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"JuJf4Hk1b":"text","LIdfem8Cn":"link","NAcu3GUJr":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKu6CeFxEr: React.ComponentType<Props> = withCSS(Component, css, "framer-VIL9n") as typeof Component;
export default FramerKu6CeFxEr;

FramerKu6CeFxEr.displayName = "Text Meta";

FramerKu6CeFxEr.defaultProps = {height: 24, width: 68};

addPropertyControls(FramerKu6CeFxEr, {JuJf4Hk1b: {defaultValue: "Text", displayTextArea: false, title: "Text", type: ControlType.String}, LIdfem8Cn: {title: "Link", type: ControlType.Link}, NAcu3GUJr: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerKu6CeFxEr, [{explicitInter: true, fonts: [{family: "Red Hat Text", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML-ZwVrPacYVFtIY.woff2", weight: "300"}]}], {supportsExplicitInterCodegen: true})